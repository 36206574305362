import React from 'react'
import NavbarFullMenu from "components/Navbar-full-menu/navbar.full-menu";
import DarkTheme from './Dark';
import Footer from 'components/Footer/footer';
const Layout = ({ children }) => {
    return (

        <DarkTheme>
            <NavbarFullMenu />
            <main>
                {children}
            </main>
            <Footer />
        </DarkTheme>

    )
}

export default Layout